import React, { useState } from "react";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import MyModal from "./MyModal";
import { useCoupon } from "../context/couponContext";
import { useAuth } from "../context/AuthContext";
import axios from "axios";

// const BASE_API_URL = "http://localhost:3000/v1";
const BASE_API_URL = "https://apiz.adam-app.xyz/v1";

const PhoneComponent = ({
  handleSubmit,
  openModal,
  country,
  value,
  setValue,
  phone,
  isValidPhoneNumber,
  setPhone,
  toast,
  CTA,
  showModal,
  closeModal,
  doNothing,
  showError,
  redirectfunction,
  redirecthome,
  showCouponCode,
  postingData,
  setLoad,
}) => {
  const { submit_function, showHeaderFooter, setShowHeaderFooter } =
    useCoupon();
  const { isDisable, setIsDisable } = useAuth();

  async function check() {
    setLoad(false); // Show loader

    // Fetch the line type from the backend API
    let lineTypeInfo = null;

    // Fetch the line type from the backend API
    try {
      const response = await axios.get(
        `${BASE_API_URL}/lookup?phoneNumber=${encodeURIComponent(phone)}`
      );
      lineTypeInfo = response.data?.data;
      if (lineTypeInfo?.type === "nonFixedVoip") {
        showError();
        return;
      }
    } catch (error) {
      console.error("Error fetching line type:", error);
      // Proceed with submit_function checks even if the lookup fails
    } finally {
      setLoad(true); // Hide loader
    }

    if (!isDisable && submit_function === "Do Nothing") {
      doNothing();
    }
    if (submit_function === "Error") {
      showError();
    }
    if (submit_function === "Call Trigger") {
      setShowHeaderFooter(false);
      openModal();
    }
    if (submit_function === "Verifying Error") {
      openModal();
    }
    if (submit_function === "Redirect") {
      redirectfunction();
    }
    if (submit_function === "Redirect Home") {
      redirecthome();
    }
    if (submit_function === "Show Coupon Code") {
      showCouponCode();
    }
  }
  return (
    <div
      className="d-flex flex-column  justify-content-center w-100"
      style={{ margin: "0px 43.4px" }}
    >
      <div className="fw-bold fs-6 text-center">Enter Phone Number:</div>
      <form onSubmit={handleSubmit(check)} className="w-100">
        <div className="d-flex flex-row align-items-center gap-2 w-100 p-2 w-100 my-3 border rounded">
          <PhoneInput
            autoComplete="tel"
            style={{
              height: "100%",
              width: "100%",
              display: "block !important",
            }}
            placeholder="Enter Phone Number"
            defaultCountry={"US"}
            value={value}
            onChange={setValue}
            error={
              phone
                ? isValidPhoneNumber(phone)
                  ? undefined
                  : "Invalid phone number"
                : "Phone number required"
            }
          />
          {/* <input
            id="phone"
            type="tel"
            name="phone"
            placeholder="Enter your 10 digit phone number"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            style={{ width: "100%" }}
          /> */}
        </div>
        {toast ? (
          <div
            style={{
              color: "red",
              fontSize: "0.8em",
              margin: 0,
              padding: 0,
            }}
          >
            Please Enter Valid Number
          </div>
        ) : null}
        <input
          type="submit"
          value="continue"
          style={{
            backgroundColor: isDisable ? "rgb(161 161 170)" : "black",
            cursor: isDisable ? "not-allowed" : "pointer",
          }}
          className={`border-0 outline-0 rounded-pill text-white fs-5 py-2 w-100 mt-2`}
        />
      </form>

      <MyModal
        phone={phone}
        CTA={CTA} // Handle 'Call Me' button
        showModal={showModal}
        closeModal={closeModal}
        postingData={postingData}
      />
      <span className="text-center my-2" style={{ fontSize: "0.4em" }}>
        I agree to the Terms & conditions and Privacy Policy.
      </span>
    </div>
  );
};

export default PhoneComponent;
