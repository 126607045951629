import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { nanoid } from "nanoid/non-secure";
import SpinnerRoundOutlined from "../package/SpinnerRoundOutlined";
import { useForm } from "react-hook-form";
import {
  isValidPhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import { useAuth } from "../context/AuthContext";
import ClipboardJS from "clipboard";
import Headers from "./DynamicOffer/headers";
import EmailComponent from "./EmailComponent";
import PhoneComponent from "./PhoneComponent";
import CouponCode from "./CouponCode";
import CallWaiting from "./CallWaiting";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCoupon } from "../context/couponContext";
import { useUserIpDetails } from "../context/UserContext";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

import { clarity } from "clarity-js";
import validatePhoneNumber from "../utils/validatePhoneNumber";

clarity.start({
  projectId: "htbj5cfswa",
  upload: "https://m.clarity.ms/collect",
  track: true,
  content: true,
});

// function throwErrorAsPromise() {
//   return new Promise((resolve, reject) => {
//     // Simulating an error condition
//     const isError = true;

//     if (isError) {
//       reject(new Error("An error occurred"));
//     } else {
//       resolve("No error occurred");
//     }
//   });
// }

const OfferName = () => {
  // User IP Details
  const { userIpDetails, updateUserIPDetails } = useUserIpDetails();
  const [sessionId, setSessionId] = useState("");

  const [load, setLoad] = useState(true);
  const [loaderText, setLoaderText] = useState(
    "Please wait while validating your email"
  );
  const [phoneSection, setPhoneSection] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [call, setCall] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isCalling, setIsCalling] = useState(false);

  const [country, setCountry] = useState(""); //set country here
  const [phoneId, setPhoneId] = useState("");
  const [toastclip, setToastclip] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { company } = useParams();

  const notify = (text = "Something Went Wrong! Please try again...") =>
    toast("⚠️" + text);

  const {
    image,
    couponCode,
    setCouponCode,
    offerpercent,
    id,
    setId,
    location,
    setLocation,
    ip,
    setIP,
    status,
    setStatus,
    countryCode,
    setCountryCode,
    regionName,
    setRegionName,
    city,
    setCity,
    timezone,
    setTimezone,
    isp,
    setIsp,
    callhash,
    setCallhash,
    setFingerprint,
    fingerprint,
    firstSubmission,
    setFirstSubmission,
    lastSubmission,
    refererWebsite,
    submissions,
    product_hash,
    setProduct_hash,
    fromNumber,
    redirecturl,
    isDisable,
    setIsDisable,
    setPhoneNumber,
  } = useAuth();
  const {
    call_conf_ans_function,
    setCall_conf_ans_function,
    submit_function,
    setSubmit_function,
    twilioResponse,
    setTwilioResponse,
  } = useCoupon();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const openModal = async () => {
    // setPhoneId("+" + getCountryCallingCode(country) + phone.replace(/\D/g, ""));
    setShowModal(true);
    await postingData();

    // if (isValidPhoneNumber(phone)) {
    //   setShowModal(true);
    // } else {
    //   setToastclip(true);
    //   setTimeout(() => {
    //     setToastclip(false);
    //   }, 5000);
    // }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // header footer
  const { showHeaderFooter, setShowHeaderFooter } = useCoupon();

  const postingData = async ({ triggerCall = false } = {}) => {
    if (!phone) {
      return { error: true };
    } else {
      const data = {
        email: email,
        fingerprint: fingerprint,
        sessionId,
        product_code: company,
        phoneNumber: phone,
        product_hash: product_hash,
        geo_ip: {
          ip_address: userIpDetails?.ip || "1.1.1.1",
          city: userIpDetails?.city || "checkApi",
          country: userIpDetails?.country_code || "checkApi",
          isp: userIpDetails?.connection?.isp || "checkApi",
          timezone: userIpDetails?.timezone?.id || "checkApi",
        },
        isCallActuallyTriggered: triggerCall,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        // https://apiz.adam-app.xyz/v1/users
        // == sending user data to database ==
        await axios.post("https://apiz.adam-app.xyz/v1/users", data, config);

        setIsDisable(true);
        return { error: false };
      } catch (error) {
        return {
          error: true,
          message: "Error! Please Try Aagain After Sometime",
        };
      }
    }
  };

  const handleSetLoad = (isLoading, component = "") => {
    setLoad(isLoading);
    setLoaderText(
      component === "phone"
        ? "Please wait while validating your phone number"
        : "Please wait while validating your email"
    );
  };

  //Call Trigger
  const twilio = async ({ userIpDetails }) => {
    setIsCalling(true);

    setCallhash(false);
    setValidating(false);
    setCall(false);

    const data = {
      sessionId,
      email: email,
      phoneNumber: phone,
      fingerprint: fingerprint,
      product_hash: product_hash,
      geo_ip: {
        ip_address: userIpDetails?.ip || "1.1.1.1",
        city: userIpDetails?.city || "checkApi",
        country: userIpDetails?.country_code || "checkApi",
        isp: userIpDetails?.connection?.isp || "checkApi",
        timezone: userIpDetails?.timezone?.id || "checkApi",
      },
      isCallActuallyTriggered: true,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // sending user data to database
      // await throwErrorAsPromise();

      // == TODO: uncomment this
      // const callres = await axios.post(
      //   "https://apiz.adam-app.xyz/v1/users",
      //   data,
      //   config
      // );

      const callres = await axios.post(
        "https://apiz.adam-app.xyz/v1/users",
        data,
        config
      );

      // implementation of call verification
      // const callData = {
      //   to: formattedNumber,
      //   from: fromNumber,
      //   product_hash: product_hash,
      // };

      // const callres = await axios.post(
      //   "https://apiz.adam-app.xyz/v1/twilio/verify/call",
      //   callData,
      //   config
      // );

      // Do Nothing function is by default implemented
      // Show Code

      if (
        callres?.data?.data === "202" &&
        call_conf_ans_function === "Show Code"
      ) {
        setCall(true);
        setValidating(true);
        setIsPremium(true);
      }
      // Do Nothing function is by default implemented
      else if (
        callres?.data?.data === "202" &&
        call_conf_ans_function === "Do Nothing"
      ) {
        // TODO: Implement Do Nothing functionality
      }
      // Please Wait
      else if (
        callres?.data?.data === "202" &&
        call_conf_ans_function === "Please Wait"
      ) {
        // TODO: Implement Please Wait / 202 functionality
      }
      //Error
      else if (
        callres?.data?.data === "202" &&
        call_conf_ans_function === "Error"
      ) {
        notify("Error");
      }
      //Redirect
      else if (
        callres?.data?.data === "202" &&
        call_conf_ans_function === "Redirect"
      ) {
        window.location.replace(redirecturl);
      } else if (callres?.data?.data === "500") {
        setTwilioResponse(true);
      }

      // if backend is sending some data which is not relevant
      else {
        notify("Something Went Wrong! Please Try Again");
        setCall(true);
        setValidating(true);
      }
    } catch (error) {
      notify("Error! Please Try Again After Sometime"); //if error happened while requesting to twilio API Calling Error
      setCall(true);
      setValidating(true);
    }
  };
  //Do Nothing Function

  const doNothing = async () => {
    postingData();
  };

  //Error
  const showError = () => {
    postingData();
    notify("Error");
  };

  //Redirect URl
  const redirectfunction = () => {
    postingData();
    window.location.replace(redirecturl);
  };

  // Redirect to home
  const redirecthome = () => {
    postingData();
    window.location.replace("/");
  };

  //const show coupon Code Direct
  const showCouponCode = async () => {
    const { error, message } = await postingData();

    if (error) {
      setToastclip(true);
      if (message) notify(message);
    } else setIsPremium(true);
  };

  //Logic of blocklist data and checking end
  const { setValidating } = useAuth();

  const CTA = (event) => {
    // call-to-action loader function
    event.preventDefault();
    closeModal();
    setValidating(false);
    setCall(false);
    twilio({ userIpDetails });
    // setTimeout(() => {
    //   setCall(true);
    //   setValidating(true);
    // }, 600000);
  };

  //premium check - header footer hide

  useEffect(() => {
    const checkPremium = async () => {
      try {
        const res = await axios.get(
          `https://apiz.adam-app.xyz/v1/coupons?couponName=${company}`
        );

        const isPremiumCoupon = res?.data?.data?.Is_Premium;
        const isGeneralCoupon = !res?.data?.data?.Is_Premium;
        // const isReferrerValid = document.referrer.includes("webtrackapp.online");

        //   // If direct URL access and the coupon is premium, redirect
        //   if ((!document.referrer || !isReferrerValid) && isPremiumCoupon) {
        //       window.location.replace("/");
        //       return;
        //   }

        setShowHeaderFooter(
          Boolean(Number(res?.data?.data?.show_header_footer))
        );

        if (isPremiumCoupon) {
          setIsPremium(false);
        }
        if (isGeneralCoupon) {
          setIsPremium(true);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false); // <-- Also set to false in case of an error
      }
    };
    checkPremium();

    // }
  }, []);

  // == Use-Effect for fetching and setting country-code ==
  useEffect(() => {
    if (userIpDetails) {
      setCountry(userIpDetails?.country_code);
      postUserDetails();
    }
  }, [userIpDetails]);

  // For each new page mount, generate a unique session id
  useEffect(() => {
    const id = nanoid(10);
    setSessionId(id);
  }, []);

  const fetchIpDetails = async () => {
    let API_KEY = process.env.REACT_APP_API_KEY;
    const resJSON = await axios.get(`https://ipwhois.pro/?key=${API_KEY}`);
    return resJSON.data;
  };

  // Function to post data to users collection
  async function postUserDetails() {
    try {
      // https://apiz.adam-app.xyz/v1/users
      // == TODO: POST email and ip details to call-logs
      await axios.post(
        "https://apiz.adam-app.xyz/v1/users",
        {
          email,
          fingerprint,
          sessionId,
          product_code: company,
          ...(userIpDetails
            ? {
                geo_ip: {
                  city: userIpDetails?.city,
                  country: userIpDetails?.country,
                  ip_address: userIpDetails?.ip,
                  isp: userIpDetails?.connection?.isp,
                  timezone: userIpDetails?.timezone?.id,
                },
              }
            : {}),
        },
        { headers: { "Content-Type": "application/json" } }
      );
    } catch (err) {
      console.error("Error:", err);
      return {
        ip: "1.1.1.1",
        city: "checkApi",
        country: "checkApi",
        isp: "checkApi",
        timezone: "checkApi",
      };
    }
  }

  async function handledClick() {
    setLoad(false);
    setPhoneSection(false);
    setTimeout(() => {
      setLoad(true);
    }, 1000);

    // == Fetch ip-details ==
    const ipDetails = await fetchIpDetails();
    updateUserIPDetails(ipDetails);

    // Create / update user details
    await postUserDetails();

    // try {
    //   // https://apiz.adam-app.xyz/v1/users
    //   // == TODO: POST email and ip details to call-logs
    //   await axios.post(
    //     "http://localhost:3000/v1/users",
    //     {
    //       email,
    //       fingerprint,
    //       sessionId: "testSession_123",
    //       product_code: company,
    //     },
    //     { headers: { "Content-Type": "application/json" } }
    //   );
    // } catch (err) {
    //   console.log("Error:", err);
    // }
  }

  const clipper = () => {
    ClipboardJS.copy(couponCode);
    setToastclip(true);
    setTimeout(() => {
      setToastclip(false);
    }, 4000);
  };
  // int-tel
  // const [value, setValue] = useState();
  //loader
  //   if (isLoading) {
  //     return <SpinnerRoundOutlined height={"100px"} width={"100px"} />;
  // }

  return (
    <div className="bg-[#1abc9c]">
      {call ? (
        <>
          <div style={{ display: image === undefined ? "none" : "inline" }}>
            <div
              style={{
                padding: "100px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
              }}
              className="d-flex align-items-center justify-content-center vw-100"
            >
              <div
                className="d-flex flex-lg-row flex-column-reverse justify-content-center align-items-center w-100"
                style={{ gap: "100px" }}
              >
                <div className=" rounded-lg shadow-lg ">
                  <ToastContainer />
                  <div
                    className="p-5 d-flex flex-column align-items-center justify-content-center"
                    style={{ width: "500px", height: "300px" }}
                  >
                    {load ? (
                      <>
                        {phoneSection ? (
                          <EmailComponent
                            handleSubmit={handleSubmit}
                            handledClick={handledClick}
                            setUltimateEmail={setEmail}
                            mail={email}
                            register={register}
                          />
                        ) : isPremium ? (
                          <CouponCode
                            toast={toastclip}
                            clipper={clipper}
                            couponCode={couponCode}
                          />
                        ) : (
                          <PhoneComponent
                            handleSubmit={handleSubmit}
                            openModal={openModal}
                            country={country}
                            value={phone}
                            setValue={(value) => {
                              if (toastclip) setToastclip(false);

                              setPhone(value);
                              setPhoneNumber(value);
                            }}
                            phone={phone}
                            isValidPhoneNumber={isValidPhoneNumber}
                            setPhone={setPhone}
                            toast={toastclip}
                            CTA={CTA}
                            showModal={showModal}
                            closeModal={closeModal}
                            doNothing={doNothing}
                            showError={showError}
                            redirectfunction={redirectfunction}
                            redirecthome={redirecthome}
                            showCouponCode={showCouponCode}
                            postingData={postingData}
                            setLoad={(isLoading) =>
                              handleSetLoad(isLoading, "phone")
                            }
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="d-flex flex-column justify-content-center align-items-center"
                          style={{ gap: "46px", padding: "0.1px 28.8px" }}
                        >
                          <SpinnerRoundOutlined
                            height={"100px"}
                            width={"100px"}
                          />
                          <div>{loaderText}</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <OfferSection
                  offerpercent={offerpercent}
                  isCalling={isCalling}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: image === undefined ? "flex" : "none",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerRoundOutlined height={"60px"} width={"60px"} />
          </div>
        </>
      ) : (
        // passing twilio function if did'nt recieve call functionality
        <CallWaiting twilio={twilio} isCalling={isCalling} />
      )}
    </div>
  );
};

const OfferSection = ({ offerpercent, isCalling }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div>
        <Headers />
      </div>
      <div className="fs-1">{offerpercent}</div>
      <div style={{ fontSize: "0.8em" }}>*Terms and Conditions may apply</div>
    </div>
  );
};
export default OfferName;
